



















































































































import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import MoreInvestmentAlternativesStepsDialogViewModel
  from
  '@/vue-app/view-models/allianz-dashboard/more-investment-alternatives/more-investment-alternatives-steps-dialog-view-model';

@Component({
  name: 'MoreInvestmentAlternativesStepsDialog',
})
export default class MoreInvestmentAlternativesStepsDialog extends Vue {
  @PropSync('isOpen', { type: Boolean })
  synced_is_open!: boolean;

  more_investment_alternatives_steps_model = Vue.observable(
    new MoreInvestmentAlternativesStepsDialogViewModel(this),
  );

  closeModal() {
    this.synced_is_open = false;
  }

  back() {
    this.synced_is_open = false;
    this.more_investment_alternatives_steps_model.back();
  }

  start() {
    this.synced_is_open = false;
    this.more_investment_alternatives_steps_model.start();
  }
}
